function setEqualHeight() {
    $(".product-detail .product-title-grid").matchHeight();
    $(".ustanoviteljice-glava .grid-element-content").matchHeight();
    $(".logos .grid-element-content").matchHeight();
    $(".grid-upcoming-product .upcoming-product-wrapper").matchHeight();
    $(".upcoming-product .description-wrapper").matchHeight();
    $(".upcoming-product .title-wrapper").matchHeight();

    $(".galerija-enako").matchHeight();
}
$(window).on("load", function() {
    setEqualHeight();
});
$(window).resize(function() {
    setEqualHeight();
});

$('#main-menu-state').change(function(e) {
    var $menu = $('#main-menu');
    var maxHeight,
        skelBreakpoints = skel.vars.stateId;
    if (this.checked) {
        $menu.hide().slideDown(250, function() {
            $menu.css('display', '');
            if (skelBreakpoints.search("/medium") > -1) {
                maxHeight = 0.95 * ($(window).height() - ($("#main-menu").offset().top - $(window).scrollTop()));
                $('#main-menu').css("max-height", maxHeight + "px");
                if ($('#main-menu').outerHeight() > maxHeight) {
                    $('#main-menu').css("height", maxHeight + "px");
                }
            }
        });
    } else {
        $menu.show().slideUp(250, function() {
            $menu.css('display', '');
            $('#main-menu').css("max-height", "");
            $('#main-menu').css("height", "");
        });
    }
});

$(window).bind('scroll', function() {
    var current_breakpoint = getSkelSize(),
        current_breakpoint_num = getNumSkelSize(),
        scroll_limit = 180,
        initial_height = 180,
        target_height = 100,
        initial_padding = 20,
        target_padding = 20,
        logo_height,
        padding;
    switch (current_breakpoint) {
        case "xxlarge":
            scroll_limit = 180;
            initial_height = 180;
            target_height = 100;
            initial_padding = 20;
            break;
        case "xlarge":
            scroll_limit = 124;
            initial_height = 144;
            target_height = 100;
            initial_padding = 20;
            break;
        case "large":
            scroll_limit = 64;
            initial_height = 104;
            target_height = 100;
            initial_padding = 20;
            break;
        default:
            scroll_limit = 0;
            initial_height = 90;
            target_height = 90;
            initial_padding = 20;
            target_padding = 20;
    }
    if (current_breakpoint_num > 3) {
        if ($(window).scrollTop() <= scroll_limit) {
            logo_height = initial_height - ($(window).scrollTop() / scroll_limit) * (initial_height - target_height);
            // padding = initial_padding - ($(window).scrollTop() / scroll_limit) * (initial_padding - target_padding);
        } else {
            logo_height = target_height;
            // padding = target_padding;
        }
        $("#logo a").css("height", logo_height + "px");
        // $("#header-main-wrapper").css("padding-top", padding + "px");
        // $("#header-main-wrapper").css("padding-bottom", padding + "px");
    } else {
        $("#logo a").css("height", "");
        // $("#header-main-wrapper").css("padding-top", "");
        // $("#header-main-wrapper").css("padding-bottom", "");
    }

});
